<template lang="pug">
    <div id="docusign" class="step-component" style="display:flex; align-items: center; justify-content: center; padding: 0">
        <div v-if="!showIframe && !finishDoc" class="loader">Loading...</div>
        <iframe v-if="showIframe" style="width: 100%; height: 100%" :src="url"></iframe>
    </div>
</template>

<style scoped>
.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>

<script>
import Vue from 'vue'

export default {
    props: {
      formURL: { type: [Object, String], required: true },
    },

    data() {
        return {
            url: '',
            showIframe: false,
            finishDoc: false
        }
    },

    created: function() {
        this.getDocusignLink();
        window.addEventListener('message', this.listenMessage);
    },

    watch: {},

    methods: {
        getDocusignLink,
        listenMessage,
        gotoNext,
        formURLText
    },

    components: {},
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function getDocusignLink() {
    const docusignStatus = this.$store.getters['user/getDocusignStatus'];
    if(docusignStatus) {
      return this.gotoNext();
    }
    const orderId = this.$store.getters['user/getMedplumOrderId'];
    const templateId = this.formURLText();
    if(orderId) {
      Vue.axios
        .get('/user-info?orderId='+orderId)
        .then(data => {
            Vue.axios
              .post('/docusign/generate-link', {
                  phone_number: data.data.phone,
                  full_name: data.data.name,
                  client_user_id: data.data.id,
                  email: data.data.email,
                  template_id: templateId
              })
              .then(postData => {
                  this.showIframe = true
                  this.$store.commit('user/SET_DOCUSIGN_STATUS', true)
                  this.url = postData.data.msg.url
              })
              .catch(postError => {
                  console.error('Error : ', postError.message)
                  this.$bugsnag.notify(postError)
              })
        })
        .catch(error => {
            console.error('Error : ', error.message)
            this.$bugsnag.notify(error)
        })
    }
}

function listenMessage(e) {
    if (typeof e.data === 'string' && e.data.includes("ds-callback")) {
        this.showIframe = false;
        this.finishDoc = true;
        this.gotoNext();
    }
}

function gotoNext() {
  this.$emit('complete')
}

function formURLText() {
  if (typeof this.formURL == 'object') {
    var keyValue = this.$store.getters['user/getConfigKeys'][this.formURL.key]
    return keyValue in this.formURL.config ? this.formURL.config[keyValue] : this.formURL.default
  } else {
    return this.formURL
  }
}
</script>